<template>
    <GoogleRouteMap
        :stops="stops"
        :height-pixels="300"
    ></GoogleRouteMap>
</template>

<script>
import {defineComponent, computed} from 'vue';
export default defineComponent({
    name: 'BookingRouteMap',
})
</script>
<script setup>
import GoogleRouteMap from "@components/GoogleRouteMap.vue";

const { booking } = defineProps({
    booking: {
        required: true,
        type: Object,
    }
})

const stops = computed(() => {
    let bookingStops = []
    bookingStops.push({
        lat: booking.origin.latitude,
        lng: booking.origin.longitude,
    })

    if (booking.stops && booking.stops.length > 0) {
        booking.stops.forEach((stop) => {
            bookingStops.push({
                lat: stop.location.latitude,
                lng: stop.location.longitude,
            })
        })
    }

    bookingStops.push({
        lat: booking.destination.latitude,
        lng: booking.destination.longitude,
    })

    return bookingStops
})

</script>

<style scoped>

</style>
