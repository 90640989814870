<template>
    <span>{{ formatDateTime }}</span>
</template>

<script>

export default {
    name: "DateTime",
    props: {
        dateTime: {
            required: true,
            type: String
        },
        format: {
            required: false,
            type: String,
            default: 'short'
        }
    },
    computed: {
        formatDateTime: function() {
            if(this.dateTime == null || this.dateTime === {} || this.dateTime == "") {
                return []
            }
            const dateTime = new Date(this.dateTime)
            const locale = new Intl.DateTimeFormat().resolvedOptions().locale;
            let options = { timeZone: window.timezone }
            switch(this.format) {
                case 'short':
                    options['dateStyle'] = 'short'
                    options['timeStyle'] = 'short'
                    break;
                case 'long':
                    options['dateStyle'] = 'long'
                    options['timeStyle'] = 'long'
                    break;
                case 'short time':
                    options['timeStyle'] = 'short'
                    break;
                case 'medium time':
                    options['timeStyle'] = 'medium'
                    break;
                case 'long time':
                    options['timeStyle'] = 'long'
                    break;
                case 'short date':
                    options['dateStyle'] = 'short'
                    break;
                case 'medium date':
                    options['dateStyle'] = 'medium'
                    break;
                case 'long date':
                    options['dateStyle'] = 'long'
                    break;
                case 'full date':
                    options['dateStyle'] = 'full'
                    break;
                default:
                    options['dateStyle'] = 'medium'
                    options['timeStyle'] = 'medium'
                    break;
            }
            return new Intl.DateTimeFormat(locale, options).format(dateTime)
        }
    }
}
</script>

<style scoped>

</style>
