export const formatCurrency = (value: number) => {
    if (typeof value !== "number" && isNaN(parseFloat(value))) {
        return value;
    }

    value = parseFloat(value.toString());

    const formatter = new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
        minimumFractionDigits: 2,
    });

    return formatter.format(value);
};
