<template>
    <div v-if="loading">
        <Loading :show-text="false" spinner-class="spinner-grow" />
    </div>
    <div v-if="journey">
        <p>
            <strong>Id: </strong>
            {{ journey?.uuid }}
        </p>
        <p>
            <strong>Vehicle: </strong>
            {{ journey?.vehicle?.description ?? "N/A" }} ({{
                journey?.vehicle?.registration_code ?? "N/A"
            }})
        </p>
        <p>
            <strong>Start Time: </strong>
            {{
                DateTime.fromISO(journey?.start_time)?.toLocaleString(
                    DateTime.DATETIME_MED_WITH_WEEKDAY
                )
            }}
        </p>
        <p>
            <strong>End Time: </strong>
            {{
                DateTime.fromISO(journey?.end_time)?.toLocaleString(
                    DateTime.DATETIME_MED_WITH_WEEKDAY
                )
            }}
        </p>
        <p>
            <strong>Driver: </strong>
            {{ journey?.driver?.name ?? "N/A" }}
        </p>
    </div>
    <div v-else>Unallocated</div>
</template>
<script setup lang="ts">
import { ref, toRefs, watch } from "vue";
import { DateTime } from "luxon";
import { Loading } from "@components";
import axios from "axios";
import { LaravelResourceResponse } from "@customTypes/LaravelCommonTypes";

interface JourneyBasicDetails {
    id: number;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
}

const loading = ref(false);
const journey = ref<undefined | JourneyBasicDetails>();

const props = withDefaults(
    defineProps<{
        journeyId: number | null | undefined;
    }>(),
    {}
);

const { journeyId } = toRefs(props);

watch(
    journeyId,
    async () => {
        if (!journeyId.value) {
            journey.value = undefined;
            return;
        }

        loading.value = true;

        await axios
            .get<LaravelResourceResponse<JourneyBasicDetails>>(
                route("api.journey-basic-details-by-id", journeyId.value)
            )
            .then((response) => {
                console.log({ response });
                journey.value = response.data;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                loading.value = false;
            });
    },
    { immediate: true }
);
</script>
