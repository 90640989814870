<template>
    <div v-if="loading">
        <Loading :show-text="false" spinner-class="spinner-grow" />
    </div>
    <div v-else-if="!loading && driverPositionUpdates.length > 0">
        <div class="row mb-2">
            <div class="col-6">
                <div>
                    <strong>Distance Travelled</strong>
                    <div>{{ distanceTravelled.toFixed(2) }}km</div>
                </div>
            </div>
            <div class="col-6">
                <div>
                    <strong>Duration</strong>
                    <div>{{ duration }}</div>
                </div>
            </div>
        </div>
        <div
            :style="{
                width: '100%',
                height: '400px',
            }"
            :id="uuid"
        ></div>
    </div>
    <div v-else class="d-flex flex-items-center justify-content-center">
        <div class="text-center">
            <SvgIcon
                class="h-14 w-14 text-secondary"
                type="mdi"
                :path="mdiMapMarker"
            ></SvgIcon>
            <div class="fs-4 mt-2 mb-3">
                There are no vehicle location updates for this booking
            </div>
            <Button
                :loading="loading"
                :icon="mdiRefresh"
                color="primary"
                @click="loadDriverPositionUpdates"
            >
                Refresh
            </Button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import DriverPositionUpdate from "@classes/DriverPositionUpdate";
import Button from "@components/Button.vue";
import {DriverPositionUpdateModalParameters} from "@components/DriverPositionUpdateModalPlugin";
import Loading from "@components/Loading.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiMapMarker, mdiRefresh} from "@mdi/js";
import {DateTime} from "luxon";
import {computed, onMounted, ref, toRefs} from "vue";
import {uuid as vueUuid} from "vue-uuid";
import mapstyle from "@themes/caremaster_theme.json";

const mapHeight = ref(300);
const uuid = ref(vueUuid.v1());
const map = ref<undefined | google.maps.Map>();
const markers = ref<google.maps.Marker[]>([]);
const bounds = ref<undefined | google.maps.LatLngBounds>();
const driverPositionUpdates = ref<App.Models.DriverPositionUpdate[]>([]);
const loading = ref(false);

const props = withDefaults(
    defineProps<{
        parameters: DriverPositionUpdateModalParameters;
    }>(),
    {}
);

const { parameters } = toRefs(props);

const loadDriverPositionUpdates = async () => {
    if (!parameters.value.bookingUuid && !parameters.value.journeyUuid) {
        console.log("Missing parameters");
        return;
    }

    loading.value = true;

    await DriverPositionUpdate.index({
        journey: parameters.value.journeyUuid,
        booking: parameters.value.bookingUuid,
    })
        .then((response) => {
            console.log(response.data);
            driverPositionUpdates.value = response.data;
        })
        .finally(() => {
            loading.value = false;
        });
};

const initMap = async () => {
    await loadDriverPositionUpdates();
    const element = document.getElementById(uuid.value ?? "");

    if (!element) {
        return;
    }

    let firstPositionRecorded = driverPositionUpdates.value?.[0];

    let lastPositionRecorded =
        driverPositionUpdates.value?.[driverPositionUpdates.value.length - 1];

    if (!lastPositionRecorded || !firstPositionRecorded) {
        return;
    }

    map.value = new google.maps.Map(element, {
        styles: mapstyle,
        zoom: 9,
        center: new google.maps.LatLng(
            lastPositionRecorded.latitude,
            lastPositionRecorded.longitude
        ),
    });

    const mapValue = map.value;

    if (!mapValue) {
        return;
    }

    driverPositionUpdates.value.map((update) => {
        let currentBounds = bounds.value;
        let position = new google.maps.LatLng(
            update.latitude,
            update.longitude
        );

        bounds.value = currentBounds;

        currentBounds?.extend(position);
        let boundsValue = bounds.value;
        if (!boundsValue) {
            return;
        }
        mapValue.fitBounds(boundsValue);
    });

    driverPositionUpdates.value.map((update) => {
        // Create an info window to share between markers.
        const infoWindow = new google.maps.InfoWindow();

        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(update.latitude, update.longitude),
            map: map.value,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 5,
                fillColor: "#10BD85",
                fillOpacity: 1,
                strokeWeight: 0,
            },
            title: "First position recorded",
        });

        // Add a click listener for each marker, and set up the info window.
        marker.addListener("click", () => {
            infoWindow.close();
            infoWindow.setContent(
                `<p>Recorded at: ${DateTime.fromISO(
                    update.position_recorded_at
                ).toFormat("yyyy-MM-dd HH:mm:ss")}</p>
                <p>Speed: ${(
                    (update?.vehicle_current_speed ?? 0) / 3.6
                ).toFixed(2)} km/h</p>
                <p>battery ${update.device_battery_percentage}%</p>`
            );
            infoWindow.open(marker.getMap(), marker);
        });
    });

    const pathTravelledPolygon = new google.maps.Polyline({
        path: driverPositionUpdates.value.map((update) => {
            return {
                lat: update.latitude,
                lng: update.longitude,
            };
        }),
        geodesic: true,
        strokeColor: "#007AF8",
        strokeOpacity: 0.8,
        strokeWeight: 3,
    });

    pathTravelledPolygon.setMap(mapValue);

    console.log({ mapValue, element });

    google.maps.event.trigger(mapValue, "resize");
};

const distanceTravelled = computed(() => {
    let distance = 0;
    let lastPosition: App.Models.DriverPositionUpdate | null = null;

    driverPositionUpdates.value.map((update) => {
        if (lastPosition) {
            distance += haversine(
                lastPosition.latitude,
                lastPosition.longitude,
                update.latitude,
                update.longitude
            );
        }

        lastPosition = update;
    });

    return distance;
});

const haversine = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371; // Earth radius in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
            Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance;
};

const duration = computed(() => {
    let firstPositionRecorded = driverPositionUpdates.value?.[0];
    let lastPositionRecorded =
        driverPositionUpdates.value?.[driverPositionUpdates.value.length - 1];

    if (!firstPositionRecorded || !lastPositionRecorded) {
        return 0;
    }

    let duration = DateTime.fromISO(
        lastPositionRecorded.position_recorded_at
    ).diff(DateTime.fromISO(firstPositionRecorded.position_recorded_at));

    // Access the duration components directly
    let hours = Math.floor(duration.as("hours"));
    let minutes = Math.floor(duration.as("minutes")) % 60;
    let seconds = Math.floor(duration.as("seconds")) % 60;

    // Build the formatted duration string
    let formattedDuration = "";

    if (hours > 0) {
        formattedDuration += `${hours} ${hours === 1 ? "hour" : "hours"}`;
    }

    if (minutes > 0) {
        formattedDuration += `${
            formattedDuration.length > 0 ? ", " : ""
        }${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
    }

    if (seconds > 0 || formattedDuration === "") {
        formattedDuration += `${
            formattedDuration.length > 0 ? ", " : ""
        }${seconds} ${seconds === 1 ? "second" : "seconds"}`;
    }

    return formattedDuration;
});

onMounted(() => {
    initMap();
});
</script>
