<template>
    <FormNumberInput
        v-if="editable"
        v-model:input="odometer"
        :mask="mask"
        :label="label"
        :placeholder="placeholder"
        :inputClass="inputClass"
        :labelClass="labelClass"
        :errorClass="errorClass"
        :validation="validation"
        @update:input="updateOdometer"
    />
    <div v-else>
        <label :class="labelClass">{{ label }}</label><br />
        <input type="hidden" ref="el" :value="odometer.value" />
        <span>{{ odometerFilter(odometer.value) }}</span>
    </div>
</template>

<script setup>
    import { reactive, watch } from 'vue'
    import * as yup from 'yup'
    import { copyValues, odometerFilter } from './Utils'
    import { FormNumberInput } from './Forms'

    const { modelValue, editable, label, placeholder, inputClass, labelClass, errorClass, validation, mask } = defineProps({
        modelValue: {
            required: true,
            type: Object
        },
        editable: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: 'Odometer'
        },
        placeholder: {
            type: String,
            default: 'e.g. 60000'
        },
        inputClass: {
            type: String,
            default: 'form-control'
        },
        labelClass: {
            type: String,
            default: 'form-label'
        },
        errorClass: {
            type: String,
            default: 'text-danger form-text'
        },
        validation: {
            type: Object,
            default: yup.number().nullable()
        },
        mask: {
            type: Object,
            default: {
                mask: Number,
                scale: 0,
                thousandsSeparator: '',
                signed: false,
            }
        }
    })

    const odometer = reactive(copyValues(modelValue))

    const emit = defineEmits(['update:modelValue'])

    const updateOdometer = (input) => {
        odometer.value = input.value
        odometer.valid = input.valid
        odometer.errors = input.errors
    }

    watch(
        odometer,
        (value) => emit('update:modelValue', value)
    )
</script>
