import { defineStore } from "pinia";
import {ref} from "vue";

export const useRevertBookingStore = defineStore( 'revertBookingStore', () => {
    const storeSelectedBookingToRevert = ref({});

    function setSelectedBookingToRevert(booking) {
        storeSelectedBookingToRevert.value = booking;
    }

    function getSelectedBookingToRevert() {
        return storeSelectedBookingToRevert.value;
    }

    return {
        storeSelectedBookingToRevert,
        setSelectedBookingToRevert,
        getSelectedBookingToRevert,
    }
});
