<template>
    <div>
        <div :id="uuid" :style="{
            'width': this.widthPercent + '%',
            'height': this.heightPixels + 'px',
        }"></div>
    </div>
</template>

<script>
import mapstyle from "@themes/caremaster_theme.json";

export default {
    name: "GoogleRouteMap",
    data() {
        return {
            allStops: [],
            map: null,
            uuid: this.$uuid.v1(),
            origin: null,
            destination: null,
            waypoints: [],
        }
    },
    props: {
        stops: {
            type: Array,
            required: true,
        },
        widthPercent: {
            type: Number,
            required: false,
            default: 100,
        },
        heightPixels: {
            type: Number,
            required: false,
            default: 200,
        },
    },
    emits: ['onDirectionsReceived'],
    methods: {
        initMap() {
            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer();
            const trafficLayer = new google.maps.TrafficLayer()

            this.allStops = this.stops.map((stop) => {
                return new google.maps.LatLng(stop.lat, stop.lng)
            })

            this.waypoints = this.allStops
            this.origin = this.allStops.shift()
            this.destination = this.allStops.pop()

            let bounds = new google.maps.LatLngBounds()
            bounds.extend(this.origin)
            this.waypoints.forEach((stop) => {
                bounds.extend(stop)
            })
            bounds.extend(this.destination)

            this.map = new google.maps.Map(document.getElementById(this.uuid), {
                disableDefaultUI: true,
                styles: mapstyle
            })

            this.map.fitBounds(bounds)

            directionsRenderer.setMap(this.map);
            trafficLayer.setMap(this.map)

            this.calculateAndDisplayRoute(directionsService, directionsRenderer);
        },
        calculateAndDisplayRoute(directionsService, directionsRenderer) {
            directionsService
                .route({
                    origin: this.origin,
                    destination: this.destination,
                    travelMode: google.maps.TravelMode.DRIVING,
                    waypoints: this.waypoints.map((waypoint) => {
                        return {
                            location: waypoint,
                            stopover: true
                        }
                    }),
                })
                .then((response) => {
                    this.$emit('onDirectionsReceived', response)
                    directionsRenderer.setDirections(response);
                })
                .catch((e) => console.error(e));
        }
    },
    mounted() {
        this.initMap()
    },
    watch: {
        stops(newStops, OldStops) {
            this.initMap()
        }
    }
}
</script>

<style scoped>

</style>
