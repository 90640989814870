import CopyJourneyModal from "./CopyJourneyModal.vue";
import { nextTick, ref } from "vue";

const copyJourneyModal = {
    install: (app: any) => {
        const copyJourney: any = {};
        let copyJourneyUuid = ref<string | null>(null);

        copyJourney.show = (uuid: string) => {
            // Force the watcher to trigger
            copyJourneyUuid.value = uuid;
            nextTick(() => {
                copyJourneyUuid.value = uuid;
            });
        };

        app.provide("copyJourney", copyJourney);
        app.provide("copyJourneyUuid", copyJourneyUuid);
        app.component("copy-journey-modal", CopyJourneyModal);
    },
};
export default copyJourneyModal;
