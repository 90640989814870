import axios from "axios";
import { LaravelResourceResponse } from "../types/LaravelCommonTypes";

export default class BookingServiceType {
    static async index(): Promise<App.Models.BookingServiceType[]> {
        let result: App.Models.BookingServiceType[] = [];

        try {
            let response: any = await axios.get(
                route("api.booking-service-types.index")
            );

            if (response?.data?.data?.some((i) => i?.uuid)) {
                result = response?.data?.data;
            }
        } catch (ex) {
            console.error(ex);
        }

        return result;
    }

    static getAllActive() {
        return axios.get<LaravelResourceResponse<App.Models.BookingServiceType[]>>(
            route("api.booking-service-types.active-list")
        )
    }

    static delete(
        bookingServiceTypeUuid: string,
    ) {
        return axios.delete(route('api.booking-service-types.destroy', bookingServiceTypeUuid));
    }

    static activate(
        bookingServiceTypeUuid: string,
    ) {
        return axios.post(route('api.booking-service-types.activate', bookingServiceTypeUuid));
    }

    static deactivate(
        bookingServiceTypeUuid: string,
        serviceTypeUuidToMove: string,
    ) {
        const payload = {
            'move_to_uuid': serviceTypeUuidToMove
        }

        return axios.post(route('api.booking-service-types.deactivate', bookingServiceTypeUuid), payload);
    }
}
