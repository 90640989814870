<template>
    <label :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
           :for="inputName">
        {{ label }}
    </label>
    <IMaskComponent
        v-model="inputValue"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :name="inputName"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :disabled="disabled"
        type="time"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage, defaultInputName} from '@components/Utils'
import {IMaskComponent} from "vue-imask";

const {
    input,
    label,
    placeholder,
    inputClass,
    labelClass,
    errorClass,
    inputName,
    validation,
    mask,
    optional,
    min,
    max,
    disabled
} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: defaultInputName()
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.string().max(255),
    },
    mask: {
        type: Object,
        default: null
    },
    optional: {
        type: Boolean,
        default: false
    },
    min: {
        type: String,
        default: null
    },
    max: {
        type: String,
        default: null
    },
    hideLabel: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const mounted = ref(false)
const inputValue = ref(copyValues(input.value))

onReady(() => mounted.value = true)

const emit = defineEmits(['update:input'])

const {errorMessage, setValue, validate, meta} = useField(inputName, validation)

watch(
    inputValue,
    (value) => {
        if (mounted.value) {
            setValue(value)
            validate().then(() => {
                let returnValue = copyValues(input)
                returnValue.value = meta.valid ? value : ''
                returnValue.valid = meta.valid
                emit('update:input', returnValue)
            })
        }
    }
)
</script>
