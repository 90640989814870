import { onMounted, computed } from 'vue'
import {map, kebabCase, isNull} from 'lodash'
import {uuid} from "vue-uuid"
import {DateTime} from 'luxon'
import {string} from "yup";
import IMask from 'imask';

const copyValues = (value) => {
    return value != undefined ? JSON.parse(JSON.stringify(value)) : value;
}

const onReady = (cb, time = 500) => {
    onMounted(() => setTimeout(cb, time))
}

const isInvalid = (meta, modelValue) => {
    return (!meta.valid && meta.dirty) || modelValue.errors?.length
}

const getErrorMessage = (modelValue, errorMessage) => {
    if (modelValue.errors?.length > 0) {
        return modelValue.errors[0]
    }
    return errorMessage
}

const showErrorMessage = (errors, defaultErrorMessage) => {
    if (!errors || errors?.length < 1) {
        return '';
    }

    if (defaultErrorMessage) {
        return defaultErrorMessage;
    }

    return errors[0];
}

const getSelectValues = (options) => {
    return map(options, option => option.value)
}

const defaultInputName = () => {
    return kebabCase((Math.random() + 1).toString(36).substring(7))
}

const toTimeStamp = (dateTime) => {
    return Math.floor(new Date(dateTime).getTime() / 1000)
}

const toDateTimeInput = (dateTime) => {
    return DateTime.fromISO(dateTime).toFormat("yyyy-MM-dd'T'HH:mm")
}

const formatDate = (date, format) => {
    if (date == null || date === "") {
        return null;
    }
    const dateTime = new Date(date);
    const locale = new Intl.DateTimeFormat().resolvedOptions().locale;
    let options = { timeZone: window.timezone };
    switch (format) {
        case "short":
            options["dateStyle"] = "short";
            options["timeStyle"] = "short";
            break;
        case "long":
            options["dateStyle"] = "long";
            options["timeStyle"] = "long";
            break;
        case "short time":
            options["timeStyle"] = "short";
            break;
        case "medium time":
            options["timeStyle"] = "medium";
            break;
        case "long time":
            options["timeStyle"] = "long";
            break;
        case "short date":
            options["dateStyle"] = "short";
            break;
        case "medium date":
            options["dateStyle"] = "medium";
            break;
        case "long date":
            options["dateStyle"] = "long";
            break;
        case "full date":
            options["dateStyle"] = "full";
            break;
        case undefined:
        case null:
        case "":
            options["dateStyle"] = "medium";
            options["timeStyle"] = "medium";
            break;
        default:
            return DateTime.fromJSDate(dateTime).setLocale('en').toFormat(format);
    }
    return new Intl.DateTimeFormat(locale, options).format(dateTime);
}

const showMessage = (message, text = null, type = 'primary', duration = 5000) => {
    message.test = text
    message.type = type
    message.duration = duration
    message.show = true
}

const transformInput = (overrides = null) => {
    const input = {
        uuid: uuid.v4(),
        value: null,
        required: true,
        valid: false,
        errors: [],
    }

    if (!overrides) {
        return input
    }

    Object.keys(overrides).forEach((key) => {
        if (input.hasOwnProperty(key)) {
            input[key] = overrides[key]
        }
    });

    return input;
}

const extractInputValues = (formObject) => {
    const values = {}

    for (const [key, value] of Object.entries(formObject)) {
        values[key] = value.value
    }

    return values;
}

const odometerFilter = (odometer, mask = null) => {
    mask = mask ?? {
        mask: Number,
        scale: 0,
        thousandsSeparator: ',',
        signed: false,
    };

    const maskInstance = IMask.createMask(mask);
    maskInstance.resolve(String(odometer));

    return maskInstance.value ? maskInstance.value + 'km' : null;
};

const regoFilter = (rego, mask = null) => {
    mask = mask ?? {
        mask: [
            {
                mask: '### ###',
                length: 6,
                definitions: {
                    '#': /[0-9a-zA-Z]/
                }
            },
            {
                mask: '# ### ###',
                length: 7,
                definitions: {
                    '#': /[0-9a-zA-Z]/
                }
            },
            {
                mask: /^[0-9a-zA-Z]{0,10}$/,
                length: 'default'
            }
        ],
        prepare: function (str) {
            return str.replace(/\s/g, '').toUpperCase();
        },
        dispatch: function (appended, dynamicMask) {
            const length = dynamicMask.value.length;
            return dynamicMask.compiledMasks.find(mask => {
                return mask.length === length || mask.length === 'default';
            });
        }
    };

    const maskInstance = IMask.createMask(mask);
    maskInstance.resolve(String(rego));

    return maskInstance.value;
};

const currencyFilter = (price) => {
    return new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'}).format(price)
}

const numberFilter = (number, mask = null) => {
    mask = mask ?? {
        mask: Number,
        scale: 0,
        signed: false,
        thousandsSeparator: ',',
    };

    const maskInstance = IMask.createMask(mask);
    maskInstance.resolve(String(number));

    return maskInstance.value;
};

// Restricts input for the given textbox to the given inputFilter function.
const setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            } else {
                this.value = "";
            }
        });
    });
}

const setNumberOnlyFilter = (elements) => {
    elements.forEach(function (element) {
        setInputFilter(element, function (value) {
            return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });
    });
}

const userCan = (action) => {
    return (window?.permissions ?? []).find(i => i == action) ? true : false;
}

const formatArray = (elements) => {
    return elements.join(", ");
}

const formatFileSize = (bytes) => {
    if(isNull(bytes)) {
        return '';
    }
    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(1000)), units.length - 1);
    let unit = units[exponent];
    let prefix = bytes < 0 ? "-" : "";

    bytes = (bytes / Math.pow(1000, exponent)).toFixed(2) * 1;

    return `${prefix}${bytes} ${unit}`;
}

export {
    copyValues,
    onReady,
    isInvalid,
    getErrorMessage,
    showErrorMessage,
    getSelectValues,
    defaultInputName,
    toTimeStamp,
    toDateTimeInput,
    formatDate,
    showMessage,
    odometerFilter,
    regoFilter,
    currencyFilter,
    numberFilter,
    transformInput,
    extractInputValues,
    setInputFilter,
    setNumberOnlyFilter,
    userCan,
    formatArray,
    formatFileSize
}
