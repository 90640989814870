<template>
    <div :id="modalId" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="header-title mt-0">
                        Copy Vehicle Run & Allocated Bookings
                    </h4>
                </div>
                <div class="modal-body pb-3">
                    <div class="d-flex justify-content-center" v-if="loading">
                        <Loading
                            :show-text="false"
                            spinner-class="spinner-grow"
                        />
                    </div>
                    <div v-else>
                        <div class="mt-2 alert alert-warning">
                            <div class="d-flex flex-row align-items-center">
                                <SvgIcon
                                    type="mdi"
                                    :path="mdiAlert"
                                    class="me-1"
                                ></SvgIcon>
                                <strong>Information about this tool</strong>
                            </div>
                            <p>
                                This tool copies the selected vehicle run to
                                future dates with all the allocated bookings
                                inside it. This tool does not create any sort of
                                link between the copied vehicle runs and
                                bookings with the original items. Unlike
                                recurring bookings or vehicle run the recurrance
                                pattern cannot be edited after copied. If you
                                need to delete a copied vehicle run and the
                                bookings inside you will need to delete each one
                                individually.
                            </p>
                        </div>

                        <table class="table table-sm table-striped">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <strong>Vehicle Run ID</strong>
                                            <span>{{ journey?.uuid }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <strong>Driver</strong>
                                            <span
                                                >{{
                                                    journey?.driver?.given_names
                                                }}
                                                {{
                                                    journey?.driver?.last_name
                                                }}</span
                                            >
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <strong>Start Time</strong>
                                            <span>{{
                                                journeyTimes
                                                    ?.startTime()
                                                    ?.toFormat("HH:mm")
                                            }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <strong>End Time</strong>
                                            <span>{{
                                                journeyTimes
                                                    ?.endTime()
                                                    ?.toFormat("HH:mm")
                                            }}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <strong>Vehicle</strong>
                                            <span
                                                >{{
                                                    journey?.vehicle
                                                        ?.description
                                                }}
                                                ({{
                                                    journey?.vehicle
                                                        ?.registration_code
                                                }})</span
                                            >
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <strong>Bookings Allocated</strong>
                                            <span>{{
                                                journey?.bookings?.length
                                            }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <CopyJourneyDatePicker
                            v-model="selectedDatePickerDates"
                        />

                        <div
                            class="d-flex flex-row align-items-center mt-3 p-2 bg-light"
                        >
                            <div class="flex-fill">
                                <strong>Avoid Duplicate Bookings</strong>
                                <div class="text-muted">
                                    When turned on, if a similar booking already
                                    exists on the selected date, a new booking
                                    will not be created, instead the existing
                                    booking will be allocated to the copied
                                    vehicle run.
                                </div>
                            </div>
                            <FormSwitch v-model="avoidDuplicateBookings" />
                        </div>

                        <div
                            class="alert alert-danger mt-2"
                            role="alert"
                            v-if="validationError"
                        >
                            {{ validationError }}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <Button class="btn btn-secondary" @click="close">
                        Close
                    </Button>
                    <Button
                        class="btn btn-primary"
                        :loading="saving"
                        @click="applyChanges"
                    >
                        Copy
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import JourneyDatetime from "@classes/DateHelpers/JourneyDatetime";
import JourneyApi from "@classes/JourneyApi";
import Button from "@components/Button.vue";
import CopyJourneyDatePicker from "@components/CopyJourneyPlugin/CopyJourneyDatePicker.vue";
import FormSwitch from "@components/Forms/FormSwitch.vue";
import Loading from "@components/Loading.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiAlert} from "@mdi/js";
import axios from "axios";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import {computed, inject, onMounted, ref, watch} from "vue";

const validationError = ref<string | undefined>();

const journey = ref<App.Models.Journey | undefined>();

const copyJourneyUuid = inject<any>("copyJourneyUuid");

const avoidDuplicateBookings = ref(true);

const loading = ref(false);
const saving = ref(false);

const selectedDatePickerDates = ref<string[]>([]);

const modalId = ref("CopyJourneyModal");

//@ts-ignore
const modal = ref<null | bootstrap.Modal>(null);

const emit = defineEmits<{
    (e: "update:show", value: boolean): void;
}>();

const show = computed({
    get() {
        return copyJourneyUuid.value !== null;
    },
    set(value) {
        if (!value) {
            copyJourneyUuid.value = null;
        }
    },
});

const applyChanges = () => {
    if (!journey.value) {
        return;
    }

    saving.value = true;
    validationError.value = undefined;

    const data = {
        journey: journey.value.uuid,
        dates: selectedDatePickerDates.value,
        avoid_duplicate_bookings: avoidDuplicateBookings.value,
    };

    axios
        .post(route("api.copy-journey"), data)
        .then((response) => {
            console.log(response.data);
            show.value = false;
            selectedDatePickerDates.value = [];
        })
        .catch((error) => {
            validationError.value = error.response.data.message;
        })
        .finally(() => {
            saving.value = false;
        });
};

const close = () => {
    show.value = false;
};

const initModal = () => {
    const modalElement = document.getElementById(modalId.value);
    if (!modalElement) {
        return;
    }
    modal.value = new bootstrap.Modal(modalElement, {});
};

const loadJourney = async () => {
    loading.value = true;
    JourneyApi.show(copyJourneyUuid.value)
        .then((response) => {
            journey.value = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            loading.value = false;
        });
};

const journeyTimes = computed(() => {
    if (!journey.value) {
        return;
    }
    return new JourneyDatetime(journey.value);
});

watch(
    () => show.value,
    (newValue) => {
        if (newValue && modal.value) {
            modal.value?.show();
            loadJourney();
        } else {
            modal.value?.hide();
        }
    }
);

onMounted(() => {
    initModal();
});
</script>
