import Booking, { BookingInterface } from "@classes/Booking";
import DriverPositionUpdateModal from "./DriverPositionUpdateModal.vue";
import { nextTick, ref } from "vue";
import { DateTime } from "luxon";

export interface DriverPositionUpdateModalParameters {
    journeyUuid?: string;
    bookingUuid?: string;
}

const DriverPositionUpdateModalPlugin = {
    install: (app: any) => {
        const driverPositionUpdates: any = {};

        let driverPositionUpdatesModalParameters =
            ref<DriverPositionUpdateModalParameters | null>(null);

        driverPositionUpdates.showForJourney = (
            journey: App.Models.Journey,
        ) => {
            driverPositionUpdatesModalParameters.value = {
                journeyUuid: journey?.uuid,
            };
        };

        driverPositionUpdates.showForBooking = (booking: BookingInterface) => {
            driverPositionUpdatesModalParameters.value = {
                bookingUuid: booking?.uuid,
            };
        };

        app.provide(
            "driverPositionUpdatesModalParameters",
            driverPositionUpdatesModalParameters
        );
        app.provide("driverPositionUpdates", driverPositionUpdates);

        app.component(
            "driver-position-update-modal",
            DriverPositionUpdateModal
        );
    },
};
export default DriverPositionUpdateModalPlugin;
