<template>
    <label
        v-if="label"
        :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
        :for="inputName"
    >
        {{ label }}
    </label>
    <textarea
        @input="validateInput"
        :value="input.value"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input) }]"
        :name="inputName"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :rows="rows"
        @blur="validateInput"
    />
    <div v-if="maxlength && input.value" class="text-end text-xs text-secondary">{{ input.value.length }}/{{ maxlength }}</div>
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {kebabCase} from 'lodash'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, isInvalid, getErrorMessage, defaultInputName} from '@components/Utils'

const {
    input,
    label,
    placeholder,
    inputClass,
    labelClass,
    errorClass,
    inputName,
    validation,
    optional,
    maxlength
} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    inputName: {
        type: String,
        default: defaultInputName()
    },
    validation: {
        type: Object,
        default: () => yup.string()
    },
    optional: {
        type: Boolean,
        default: false
    },
    maxlength: {
        type: String,
        required: false,
        default: null,
    },
    rows: {
        type: String,
        required: false,
        default: '3',
    },
})

    const emit = defineEmits(['update:input'])
    const { errorMessage, setValue, validate, meta } = useField(inputName, validation)

    const validateInput = (event) => {
        const value = event.target.value
        setValue(value)
        validate().then(() => {
            let returnValue = copyValues(input)
            returnValue.value = value;
            returnValue.valid = meta.valid
            emit('update:input', returnValue)
        })
    }
</script>