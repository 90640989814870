import {ref} from 'vue'
import axios from 'axios'
import {DateTime as LuxonDateTime} from 'luxon'

const rescheduleBookingPlugin = {
    install: (app, customOptions = {}) => {
        const bookingToBeRescheduled = {}
        const booking = ref(null)
        const updatedBooking = ref(null)
        const newBookingDate = ref(null)
        const submitting = ref(false)

        bookingToBeRescheduled.submitFor = (bookingToReschedule, params = {}) => {
            booking.value = bookingToReschedule
            booking.value.refresh_key = Date.now()
            newBookingDate.value = params
        }

        bookingToBeRescheduled.getBooking = () => {
            return booking.value
        }

        bookingToBeRescheduled.getNewBookingDate = () => {
            return newBookingDate.value
        }

        bookingToBeRescheduled.isSubmitting = () => {
            return booking.value !== null && submitting.value === true
        }

        bookingToBeRescheduled.getUpdatedBooking = () => {
            return updatedBooking.value
        }

        bookingToBeRescheduled.shouldPromptForDeallocation = () => {
            const journey = booking.value.journey

            if (!journey) {
                return false;
            }

            const runStartTime = LuxonDateTime.fromISO(journey.start_time).toSeconds()
            const runEndTime = LuxonDateTime.fromISO(journey.end_time).toSeconds()
            const newBookingTime = newBookingDate.value.dateTime.toSeconds()

            return newBookingTime < runStartTime || newBookingTime > runEndTime
        }

        bookingToBeRescheduled.submitReschedule = () => {
            submitting.value = true

            const requestParams = newBookingDate.value.timePoint === 'origin' ? {
                planned_origin_time: newBookingDate.value.dateTime.toSeconds(),
                origin_time_requires_confirmation: false,
            } : {
                planned_destination_time: newBookingDate.value.dateTime.toSeconds(),
            }

            delete booking.value.refresh_key

            return new Promise((resolve, reject) => {
                axios
                    .put(route('api.bookings.update', { booking: booking.value.uuid }), requestParams)
                    .then((response) => {
                        updatedBooking.value = response.data
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    }).finally(() => {
                        submitting.value = false
                    })
            })
        }

        app.provide('rescheduleBooking', bookingToBeRescheduled)
    }
}

export default rescheduleBookingPlugin
