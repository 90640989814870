<template>
    <label v-if="label" :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']" :for="inputName">
        {{ label }}
    </label>
    <input
        @blur="validateInput"
        :value="input.value"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :name="inputName"
        type="date"
        :min="min"
        :max="max"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {ref} from 'vue'
import {isEmpty} from 'lodash'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage, defaultInputName} from '@components/Utils'

const {input, label, placeholder, inputClass, labelClass, errorClass, inputName, validation, min, max, pattern} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: defaultInputName()
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.date(),
    },
    min: {
        type: String,
        default: null
    },
    max: {
        type: String,
        default: null
    },
    optional: {
        type: Boolean,
        default: false
    },
})

const mounted = ref(false)

const inputValue = ref(input.value)

onReady(() => mounted.value = true)

const emit = defineEmits(['update:input'])

const {errorMessage, setValue, validate, meta} = useField(inputName, validation)

const validateInput = (event) => {
    if (validation.spec.nullable && isEmpty(event.target.value)) {
        let returnValue = copyValues(input);
        returnValue.value = null;
        returnValue.valid = true;
        emit('update:input', returnValue);
        return;
    }

    const value = event.target.value;
    setValue(value)
    validate().then(() => {
        let returnValue = copyValues(input)
        returnValue.value = meta.valid ? value : null
        returnValue.valid = meta.valid
        emit('update:input', returnValue)
    });
}
</script>
