import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import BookingCancellationReason from "../classes/BookingCancellationReason";
import PersistedStore from "./PersistedStore";

const dataExpiryThresholdMinutes = 60 * 24; // 1 day;

export const useBookingCancellationReasonStore = defineStore(
    "bookingCancellationReasonStore",
    () => {
        const bookingCancellationReasons = ref<
            App.Models.BookingCancellationReason[]
        >([]);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<
            App.Models.BookingCancellationReason[]
        > => {
            return new Promise((resolve, reject) => {
                BookingCancellationReason.index()
                    .then((response) => {
                        bookingCancellationReasons.value = response.data.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(bookingCancellationReasons.value);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                bookingCancellationReasons.value
            );
        };

        return {
            bookingCancellationReasons,
            lastRefreshTime,
            sync,
        };
    },
    {
        persist: {
            key:
                "bookingCancellationReasonStore_" +
                window?.tenant?.tenancy_db_name,
        },
    }
);
