<template>
    <label :class="[labelClass, input.required ? 'required-label' : '']" :for="inputName">
        {{ label }}
    </label>
    <IMaskComponent
        type="number"
        v-model="inputValue"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :name="inputName"
        :placeholder="placeholder"
        @accept="updated"
        @complete="updated"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage, defaultInputName} from '@components/Utils'
import {IMaskComponent} from "vue-imask";

const {input, label, inputName, placeholder, inputClass, labelClass, errorClass, validation, mask} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: defaultInputName()
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.number().nullable(),
    },
    mask: {
        type: Object,
        default: {
            mask: Number,
            scale: 2,
            signed: false,
            radix: '.',
        }
    }
})

const modifier = Math.pow(10, mask.scale)
const mounted = ref(false)
const inputValue = ref(copyValues(input.value ? input.value / modifier : "0.00"));

onReady(() => mounted.value = true)

const emit = defineEmits(['update:input'])

const {errorMessage, setValue, validate, meta} = useField(inputName ?? defaultInputName(), validation)

const updated = (value: unknown, event: CustomEvent) => {
    if (mounted.value) {
        setValue(value)
        validate().then(() => {
            let returnValue = copyValues(input)
            returnValue.value = meta.valid ? value : null
            returnValue.valid = meta.valid
            emit('update:input', returnValue)
        })
    }
}
</script>
