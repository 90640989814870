import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import BookingServiceType from "@classes/BookingServiceType";
import PersistedStore from "./PersistedStore";

const dataExpiryThresholdMinutes = 30;

export const useBookingServiceTypeStore = defineStore(
    "bookingServiceTypeStore",
    () => {
        const bookingServiceTypes = ref<App.Models.BookingServiceType[]>([]);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<App.Models.BookingServiceType[]> => {
            return new Promise((resolve, reject) => {
                BookingServiceType.getAllActive()
                    .then((response) => {
                        bookingServiceTypes.value = response.data.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(bookingServiceTypes.value);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                bookingServiceTypes.value
            );
        };

        return {
            bookingServiceTypes,
            lastRefreshTime,
            sync,
        };
    },
    {
        persist: {
            key: "bookingServiceTypeStore_" + window?.tenant?.tenancy_db_name,
        },
    }
);
