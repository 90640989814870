import {onMounted, Ref, ref} from "vue";

export interface WindowItemsInterface {
    permissions: App.Models.Permission[];
    roles: App.Models.Role[];
    user: App.Models.User;
    tenant: App.Models.Company;
    user_type: "Admin" | "User";
    enabled_features: App.Models.EnabledFeature[];
    selected_region: App.Models.Region;
    enums: {
        titles: any;
        bookingPurpose: any;
        bookingStatus: any;
        bookingPriorities: any;
        paymentMethod: any;
        fareCollectionMethod: any;
        vehicleRunStatus: any;
        queryOperators: any;
    };
}

export default function useWindow(): Ref<WindowItemsInterface | undefined> {
    const result: Ref<WindowItemsInterface | undefined> = ref<WindowItemsInterface | undefined>();

    onMounted(() => (result.value = window as unknown as WindowItemsInterface));

    return result;
}
