import axios from "axios";
import { LocationInterface } from "./Location";
import { DateTime } from "luxon";

export interface OptimizerEstimatedTimingRequest {
    arrive: null | DateTime;
    depart: null | DateTime;
    duration: number;
    flexibility: {
        early: number;
        late: number;
    };
}

export interface OptimiserTimingDatetimeResponse {
    arrive_estimation: DateTime;
    arrive_no_later_than: DateTime;
    arrive_not_before: DateTime;
    booking_id: string;
    client_name: null | string;
    depart_estimation: DateTime;
    depart_no_later_than: DateTime;
    depart_not_before: DateTime;
    distance: null;
}

export interface OptimizerEstimatedTimingResponse {
    total_distance: number;
    timings: Array<OptimiserTimingDatetimeResponse>;
}

export interface OptimizerEstimatedTimingsRequestOptions {
    stops: string[][];
    timings: OptimizerEstimatedTimingRequest[];
    group_booking?: boolean;
}

export interface OptimiserUnscheduledReason {
    booking_id: string;
    client_name: string | null;
    reason: string;
}

export interface OptimiserConstraintViolation {
    booking_id: string;
    constraint_violation: string;
}

export interface OptimiserScheduleResponseJourneyRouteObject {
    order: number;
    node_type: number;
    location_id: null | string;
    booking_id: null | string;
    stop_id: null | string;
    constraint_violation: string;
    arrive: string;
    begin: string;
    depart: string;
    wait_sec: number;
    verification_status: number;
}

export interface OptimiserScheduleRequestRawResponse {
    solution_id: string;
    messages: string;
    status: "completed" | "in_progress" | "failed";
    unscheduled: OptimiserUnscheduledReason[];
    total_metrics: {
        cpu_time_sec: number;
        num_of_used_vehicles: number;
        num_of_unused_vehicles: number;
        num_of_unscheduled_bookings: number;
        fitness_value: number;
        num_of_scheduled_bookings: number;
        empty_travel_time: number;
        travel_time: number;
        travel_distance: number;
        driver_wait: number;
        route_dur: number;
        extra_ride_time: number;
        delay: number;
        over_work_hour_limit: number;
        over_ride_time_limit: number;
        over_capaciated: number;
    };
    journeys: {
        rego: string;
        vehicle_id: string;
        driver_id: null | string;
        depart_depot: string;
        arrive_at_depot: string;
        metrics: {
            num_of_scheduled_bookings: number;
            empty_travel_time: number;
            travel_time: number;
            travel_distance: number;
            driver_wait: number;
            route_dur: number;
            extra_ride_time: number;
            delay: number;
            over_work_hour_limit: number;
            over_ride_time_limit: number;
            over_capaciated: number;
        };
        journey_route: OptimiserScheduleResponseJourneyRouteObject[] | null;
    }[];
}

export default class Optimiser {
    static responseDateFormat = "yyyy-MM-dd HH:mm:ss";

    static async estimateTimings(
        options: OptimizerEstimatedTimingsRequestOptions
    ): Promise<OptimizerEstimatedTimingResponse | null> {
        let timings = options.timings.map((timing) => {
            return {
                ...timing,
                ...{
                    arrive: timing?.arrive?.toFormat("HH:mm") ?? null,
                    depart: timing?.depart?.toFormat("HH:mm") ?? null,
                },
            };
        });

        let data = {
            ...options,
            ...{ timings, timezone: window.timezone },
        };

        if (options.stops.length < 2 || options.timings.length < 2) {
            return null;
        }

        let response = await axios.post(
            route("optimiser.estimate-timings"),
            data
        );

        //Convert optimizer response times to luxon for ease of use
        if (
            typeof response?.data?.timings?.some(
                (timing: any) => timing?.arrive_estimation
            )
        ) {
            return {
                ...response?.data,
                ...{
                    timings: response?.data?.timings.map((timing: any) => {
                        let returnObject: any = { ...timing };
                        [
                            "arrive_estimation",
                            "arrive_no_later_than",
                            "arrive_not_before",
                            "depart_estimation",
                            "depart_no_later_than",
                            "depart_not_before",
                        ].map((convertableAttribute) => {
                            returnObject[convertableAttribute] = returnObject?.[
                                convertableAttribute
                            ]
                                ? DateTime.fromISO(
                                      returnObject?.[convertableAttribute]
                                  )
                                : null;
                        });

                        return returnObject;
                    }),
                },
            };
        }

        return null;
    }

    static allocateBookingToOptimiserSolution(
        optimiserSolutionId,
        bookingUuid,
        journeyUuid
    ) {
        let allocations = {};
        allocations[bookingUuid] = journeyUuid;

        return axios.post(route("optimiser.allocate-bookings"), {
            optimiser_solution: optimiserSolutionId,
            allocations: allocations,
        });
    }

    static deAllocateBookingFromOptimiser(
        optimiserSolutionId: string | number,
        bookingUuid: string
    ) {
        return axios.post(route("optimiser.deallocate-bookings"), {
            optimiser_solution: optimiserSolutionId,
            bookings: [bookingUuid],
        });
    }

    static schedule(parameters: {
        optimiser_solution?: string;
        bookings: string[];
        journeys: string[];
        date: string;
    }) {
        console.error({ parameters });
        return axios.post<OptimiserScheduleRequestRawResponse>(
            route("optimiser.auto-allocate"),
            parameters
        );
    }

    static asyncAutoAllocate(parameters: {
        optimiser_solution?: string;
        bookings: string[];
        journeys: string[];
        date: string;
    }){
        return axios.post(
            route("optimiser.auto-allocate.async"),
            parameters
        );
    }

    static asyncAutoAllocationSolutionDetail(solutionId: string) {
        return axios.get(
            route("optimiser.solution.detail", {solutionId: solutionId}),
        )
    }
}
