<template>
    <div class="d-flex flex-row align-items-center">
        <div class="flex-fill me-2">
            <input
                v-model="selectedDatePickerDate"
                :min="DateTime.now().minus({ years: 1 }).toFormat('yyyy-MM-dd')"
                type="date"
                class="form-control"
            />
        </div>

        <Button class="btn btn-primary" @click="addDate"> Add Date </Button>
    </div>
    <div
        class="d-flex flex-row flex-wrap bg-light p-2 mt-3 br-1 rounded align-items-center"
        v-if="value.length > 0"
    >
        <div
            v-for="date in value"
            :key="date"
            class="badge bg-primary text-light m-1"
        >
            <span>{{
                DateTime.fromFormat(date, "yyyy-MM-dd").toFormat(
                    "EEE, d MMM yyyy"
                )
            }}</span>
            <SvgIcon
                type="mdi"
                :path="mdiCloseCircle"
                class="ms-1 text-white"
                :size="14"
                @click="
                    () =>
                        emit(
                            'update:modelValue',
                            value.filter((d) => d !== date)
                        )
                "
            ></SvgIcon>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, toRef, watch, inject } from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import Button from "@components/Button.vue";
import Loading from "@components/Loading.vue";
import JourneyApi from "@classes/JourneyApi";
import JourneyDatetime from "@classes/DateHelpers/JourneyDatetime";
import { DateTime } from "luxon";
import { mdiCloseCircle } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

const defaultDateSelectValue = DateTime.now().toFormat("yyyy-MM-dd");

const selectedDatePickerDate = ref<string>(defaultDateSelectValue);

const emit = defineEmits<{
    (e: "update:modelValue", newValue: string[]): void;
}>();

const props = withDefaults(
    defineProps<{
        modelValue: string[];
    }>(),
    {
        //@ts-ignore
        modelValue: [],
    }
);

const addDate = () => {
    if (selectedDatePickerDate.value) {
        emit(
            "update:modelValue",
            [...value.value, selectedDatePickerDate.value]
                .filter(unique)
                .sort(orderByDate)
        );
    }
};

const unique = (value: any, index: number, array: any[]) => {
    return array.indexOf(value) === index;
};

const orderByDate = (a: string, b: string) => {
    return DateTime.fromFormat(a, "yyyy-MM-dd").diff(
        DateTime.fromFormat(b, "yyyy-MM-dd")
    ).milliseconds;
};

let value = toRef(props, "modelValue");
</script>
