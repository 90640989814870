const tooltipPlugin = {
    install(app, options) {
        const initTooltip = (el, binding) => {
            let position = binding.arg || "top";
            let tooltipText = binding.value || "Tooltip text";
            el.setAttribute("position", position);
            el.setAttribute("tooltip", tooltipText);
        }

        app.directive("tooltip", {
            mounted(el, binding) {
                initTooltip(el, binding);
            },
            updated(el, binding) {
                initTooltip(el, binding);
            }
        });
    }
}

export default tooltipPlugin