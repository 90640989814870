<template>
    <label :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
           :for="inputName">
        {{ label }}
    </label>
    <IMaskComponent
        :value="props.input.value"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :mask="mask?.mask"
        :name="inputName"
        :placeholder="placeholder"
        :step="step"
        :type="type"
        @accept="updated"
        @complete="updated"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from 'vue'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage, defaultInputName} from '@components/Utils'
import {IMaskComponent} from "vue-imask";

const props = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: defaultInputName(),
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.number().nullable(),
    },
    optional: {
        type: Boolean,
        default: false
    },
    step: {
        type: String,
        default: '1'
    },
    mask: {
        type: Object,
        default: {
            mask: Number,
            scale: 0,
            signed: false,
            thousandsSeparator: ''
        }
    },
    type: {
        type: String,
        default: 'number'
    }
});

// we have to split the const like this to make watchers work for entire props
const {input, label, inputName, placeholder, inputClass, labelClass, validation, optional, step, mask} = props;

const mounted = ref(false)

onReady(() => mounted.value = true)

const emit = defineEmits(['update:input'])

const {errorMessage, setValue, validate, meta} = useField(<string>inputName, validation)

const updated = (value: unknown, event: CustomEvent) => {
    if (mounted.value) {
        setValue(value)
        validate().then(() => {
            let returnValue = copyValues(input)
            returnValue.value = value
            returnValue.valid = meta.valid
            emit('update:input', returnValue)
        })
    }
}

</script>
