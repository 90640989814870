<template>
    <div :id="componentId + '_modal'" :aria-labelledby="componentId + '_modal_label'" aria-hidden="true"
         class="modal fade text-start" tabindex="-1" v-if="isModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Revert Booking #{{booking.uuid}}</h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body">
                    <b>Revert Reason:</b>
                    <div>
                        {{booking.revert_reason}}
                    </div>
                    <div class="modal-footer">
                        <Button @click="revertBooking">Revert</Button>
                        <button class="btn btn-light" @click="declineBooking">Decline</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2" v-if="!isModal && showRevertPanel">
        <div class="card col offset-md-1 col-md-10">
            <div class="card-header">
                <b>Revert Reason:</b>
            </div>
            <div class="card-body">
                    {{booking.revert_reason}}
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between">
                    <Button @click="revertBooking">Revert</Button>
                    <button class="btn btn-light" @click="declineBooking">Decline</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from "@components/Button.vue";
import {computed, inject, onMounted, ref, watch} from "vue";
import axios from "axios";
import {uuid} from "vue-uuid";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import { storeToRefs } from "pinia";
import { useRevertBookingStore } from "@stores/RevertBookingStore.";

const componentId = ref(uuid.v4());
const modal = ref<null | bootstrap.Modal>(null);
const toast: any = inject('toast');
const emit = defineEmits(["bookingActioned"]);
const revertBookingStore = useRevertBookingStore();
const {storeSelectedBookingToRevert} = storeToRefs(revertBookingStore);

const props = withDefaults(
    defineProps<{
        selectedBooking?: {};
        isModal?: boolean,
        showPanel?: boolean,
    }>(), {
        selectedBooking: undefined,
        isModal: true,
        showPanel: false,
    }
);

const booking = ref<any>({});

const showRevertPanel = ref(false);

const revertBooking = () => {
    axios.post(route('api.bookings.revert.approve', {
        booking: booking.value.uuid
    }))
        .then(response => {
            booking.value = response.data;
            storeSelectedBookingToRevert.value = response.data;
            emit('bookingActioned', booking.value);

            if (props.isModal) {
                modal.value.hide();
            }

            showRevertPanel.value = false;
        })
        .catch(error => {
            toast.error('There was an error reverting booking.');
        });
}

const declineBooking = () => {
    axios.post(route('api.bookings.revert.decline', {
        booking: booking.value.uuid
    }))
        .then(response => {
            booking.value = response.data;
            storeSelectedBookingToRevert.value = response.data
            emit('bookingActioned', booking.value);
            if (props.isModal) {
                modal.value.hide();
            }

            showRevertPanel.value = false;
        })
        .catch(error => {
            toast.error('There was an error declining revert booking.');
        });
}

onMounted(() => {
    if (props.isModal) {
        modal.value = new bootstrap.Modal(document.getElementById(componentId.value + '_modal'));
    }
});

watch(() => props.selectedBooking, (value) => {
    booking.value = value;

    if (props.isModal) {
        if (booking.value) {
            modal.value.show();
        } else {
            modal.valuel.hide();
        }
    }
});

watch(() => props.showPanel, (value) => {
    showRevertPanel.value = value;
});
</script>

<style scoped>

</style>
