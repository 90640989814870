<template>
    <div class="switch-wrapper">
        <label v-if="props.label" :for="props.id">
            {{ props.label }}
        </label>
        <input
            class="switch boolean"
            :class=switchColour()
            type="checkbox"
            :value="props.value"
            :checked="props.value"
            :id="props.id"
            @change="updateValue"
            role="switch"
        />
    </div>
</template>    
    
<script setup lang="ts">
    import { uuid } from 'vue-uuid';

    const props = withDefaults(
        defineProps<{
            value: string | boolean,
            label?: string,
            colour?: string,
            name?: string,
            id?: string,
        }>(),
        {
            colour: 'bg-primary', 
            id: uuid.v4()
        }
    )

    const emit = defineEmits<{
        (e: "update:value", newValue: boolean): void;
    }>();

    const updateValue = () => {
        emit('update:value', !props.value);
    }

    const switchColour = () => {
        return props.value ? props.colour : null;
    }
</script>

<style>
    .switch-wrapper {
        margin-left: 2.5rem;
    }

    .switch {
        width: 2em;
        margin-left: -2.5em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dee2e6'/%3e%3c/svg%3e");
        background-position: left center;
        border-radius: 2em;
        transition: background-position 0.15s ease-in-out;
        height: 1.112em;
        margin-top: 0.194em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: contain;
        border: 1px solid #dee2e6d0;
        appearance: none;
        float: left;
    }
    .switch:checked {
        background-position: right center;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        background-color: v-bind(props.colour);
    }
</style>