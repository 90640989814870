import { defineStore } from "pinia";
import { ref, watch } from "vue";
import Region from "@classes/Region";
import { useMessageStore } from "@stores/MessageStore";
import { DateTime } from "luxon";
import PersistedStore from "./PersistedStore";

const dataExpiryThresholdMinutes = 90;

export const useRegionStore = defineStore(
    "regionStore",
    () => {
        const messageStore = useMessageStore();
        const { addToastMessage } = messageStore;

        const regions = ref<App.Models.Region[]>([]);
        const selectedRegion = ref<App.Models.Region | null>(
            window.selected_region
        );
        const regionLoading = ref<boolean>(false);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<App.Models.Region[]> => {
            return new Promise((resolve, reject) => {
                Region.index()
                    .then((response) => {
                        regions.value = response.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(regions.value);
                    })
                    .catch((error) => {
                        addToastMessage("error", "Failed to get regions list");
                        console.error(error);
                        reject(error);
                    })
                    .finally(() => {
                        regionLoading.value = false;
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                regions.value
            );
        };

        const getRegions = () => {
            regionLoading.value = true;

            Region.index()
                .then((resp) => {
                    regions.value = resp.data;
                })
                .catch((err) => {
                    addToastMessage("error", "Failed to get regions list");
                    console.error(err);
                })
                .finally(() => {
                    regionLoading.value = false;
                });
        };

        const selectRegion = (region: App.Models.Region | null) => {
            regionLoading.value = true;

            Region.selectRegion(region)
                .then(() => {
                    selectedRegion.value = region;
                    window.selected_region = region;
                    // location.reload(); // ToDo remove this when we dynamically update the filters on data
                })
                .catch((err) => {
                    addToastMessage("error", "Failed to set selected region");
                    console.error(err);
                })
                .finally(() => {
                    regionLoading.value = false;
                });
        };

        return {
            regions,
            selectedRegion,
            regionLoading,
            getRegions,
            selectRegion,
            sync,
            lastRefreshTime,
        };
    },
    {
        persist: {
            key: "regionStore_" + window?.tenant?.tenancy_db_name,
        },
    }
);
