<template>
    <label v-if="showLabel" :for="inputName" :class="[
        labelClass,
        selection.required ? 'required-label' : '',
        optional ? 'optional-label' : '',
    ]">
        {{ label }}
    </label>
    <slot name="label-suffix"></slot>
    <select v-model="selected" class="form-select" :tabindex="tabindex" :class="[inputClass, {'is-invalid': isInvalid(meta, selection)}]" :name="inputName" :disabled="disabled">
        <option v-if="placeholderOption" :disabled="selection.required" :value="null">{{ placeholder }}</option>
        <option v-for="(option, index) in thisOptions" :key="index" :value="option.value">{{ option.label }}</option>
    </select>
    <span :class="errorClass">{{ getErrorMessage(selection, errorMessage) }}</span>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
    import { kebabCase } from 'lodash'
    import { useField } from 'vee-validate'
    import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage, defaultInputName} from '@components/Utils'

    const props = defineProps({
        selection: {
            required: true,
            type: Object,
        },
        options: {
            required: true,
            type: Object,
        },
        label: {
            type: String,
            default: null,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        inputName: {
            type: String,
            default: defaultInputName(),
        },
        placeholder: {
            type: String,
            default: '',
        },
        inputClass: {
            type: String,
            default: 'form-select',
        },
        labelClass: {
            type: String,
            default: 'form-label'
        },
        errorClass: {
            type: String,
            default: 'text-danger form-text'
        },
        validation: {
            type: Object,
            default: yup.string().nullable(),
        },
        placeholderOption: {
            type: Boolean,
            default: true,
        },
        tabindex: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        },
        optional: {
            type: Boolean,
            default: false
        }
    })

    // we have to split the const like this to make watchers work for entire props
    const {
        selection,
        options,
        label,
        showLabel,
        inputName,
        placeholder,
        inputClass,
        labelClass,
        errorClass,
        validation,
        tabindex,
        optional,
    } = props;

    const mounted = ref(false)
    const selected = ref(copyValues(selection.value));
    const thisOptions = ref(copyValues(options));

    onReady(() => mounted.value = true)

    const emit = defineEmits(['update:selection'])

    const { errorMessage, setValue, validate, meta } = useField(inputName, validation)

    watch(
        selected,
        (value) => {
            if (mounted) {
                setValue(value)
                validate().then(() => {
                    let returnValue = copyValues(selection)
                    returnValue.value = meta.valid ? value : ''
                    returnValue.valid = meta.valid
                    emit('update:selection', returnValue)
                })
            }
        }
    )

    watch(props, (value) => {
        selected.value = copyValues(props.selection.value);
        thisOptions.value = value.options;
    });
</script>
