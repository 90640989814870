import { DateTime } from "luxon";
import DatetimeHelper from "./DatetimeHelper";

//Helper class so I don't have to keep converting the datetime strings to luxon
export default class BookingDatetime {
    booking: App.Models.Booking;

    constructor(booking: App.Models.Booking) {
        this.booking = booking;
    }

    plannedOriginTime() {
        return DatetimeHelper.convertToLuxon(this.booking.planned_origin_time);
    }

    estimatedOriginTime() {
        return DatetimeHelper.convertToLuxon(
            this.booking.estimated_origin_time
        );
    }

    plannedDestinationTime() {
        return DatetimeHelper.convertToLuxon(
            this.booking.planned_destination_time
        );
    }

    estimatedDestinationTime() {
        return DatetimeHelper.convertToLuxon(
            this.booking.estimated_destination_time
        );
    }

    appointmentTime() {
        return DatetimeHelper.convertToLuxon(this.booking.appointment_time);
    }

    displayableOriginTime() {
        return DatetimeHelper.convertToLuxon(
            this.booking.planned_origin_time ??
                this.booking.estimated_origin_time
        );
    }

    displayableDestinationTime() {
        return DatetimeHelper.convertToLuxon(
            this.booking.planned_destination_time ??
                this.booking.estimated_destination_time
        );
    }
}
