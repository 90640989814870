<template>
    <div class="card">
        <div class="card-header">
            <h5>Generate HACC MDS Reports</h5>
        </div>
        <div class="card-body">
            <FormRow>
                <FormCol lg="6">
                    <FormSelect
                        v-model:selection="form.year"
                        label="Reporting Year"
                        :options="yearOptions()"
                        placeholder="Select the year for reporting"
                    />
                </FormCol>
                <FormCol lg="6">
                    <FormSelect
                        v-model:selection="form.qtr"
                        label="Reporting Quarter"
                        :options="qtrOptions"
                        placeholder="Select the quarter for reporting"
                    />
                </FormCol>
            </FormRow>
            <FormRow>
                <FormCol>
                    <FormSelect
                        v-model:selection="form.fundingType"
                        label="Reporting Funding Type"
                        :options="fundingTypeOptions"
                        placeholder="Select the funding type for reporting"
                    />
                </FormCol>
            </FormRow>
            <Button :icon="mdiDownload"
                    :loading="preparingDownload"
                    @click="toggleExportDialog"
                    :disabled="invalid"
                    color="primary"
                    size="small">
                <template v-if="preparingDownload">Preparing file...</template>
                <template v-else>Download</template>
            </Button>
        </div>
        <DownloadReportModal
            :year="form.year.value"
            :quarter="form.qtr.value"
            :funding-type="form.fundingType.value"
            :path="route('api.reports.hacc-mds.generate')"
            :show="showExportDialog"
            module="hacc_mds"
            @update:show="toggleExportDialog"
        ></DownloadReportModal>
    </div>
</template>

<script setup lang="ts">
import DownloadReportModal from "@components/Reports/DownloadReportModal.vue";
import {ref, reactive, onMounted, computed} from 'vue';
import {storeToRefs} from "pinia";
import {DateTime} from 'luxon';
import {range, each} from 'lodash';
import Button from '@components/Button.vue';
import FormSelect from '@components/Forms/FormSelect.vue';
import FormRow from '@components/Forms/Layouts/FormRow.vue'
import FormCol from '@components/Forms/Layouts/FormCol.vue'
import {transformInput} from '@components/Utils'
import FundingType from "@classes/FundingType";
import {useFundingTypeStore} from "@stores/FundingTypeStore";
import {mdiDownload} from "@mdi/js"

const fundingTypeStore = useFundingTypeStore();
const {fundingTypeOptions} = storeToRefs(fundingTypeStore);
const {getFundingTypeOptions} = fundingTypeStore;

const form = reactive({
    qtr: transformInput(),
    year: transformInput(),
    fundingType: transformInput(),
});

const qtrOptions = [
    {
        value: '1',
        label: '1: Jan - Mar',
    },
    {
        value: '2',
        label: '2: Apr - June',
    },
    {
        value: '3',
        label: '3: July - Sep',
    },
    {
        value: '4',
        label: '4: Oct - Dec',
    },
]

const yearOptions = () => {
    return range(2023, DateTime.now().year + 1).map((year) => {
        return {
            value: year,
            label: year,
        }
    })
}

const preparingDownload = ref(false);

const download = () => {
    preparingDownload.value = true;

    let data = {
        download: true,
        year: form.year.value,
        qtr: form.qtr.value,
        fundingType: form.fundingType.value,
    }

    location.href = route('hacc-mds', data);

    setTimeout(() => {
        preparingDownload.value = false;
    }, 3000);
}

const invalid = computed(() => {
    each(form, (item) => {
        if (!item.valid) {
            return true;
        }
    });
    return false;
});

const showExportDialog = ref(false);

const toggleExportDialog = () => {
    showExportDialog.value = !showExportDialog.value;
}

onMounted(() => {
    getFundingTypeOptions();
})
</script>