<template>
    <span
        v-if="!editManualFeeForm"
        @click="showEditFeeForm"
        :class="[editable ? 'click-to-edit' : 'text-muted']"
    >
        <template v-if="booking.total_cost !== null">
            {{ formatCurrency(initialTotalCost) }}
        </template>
        <template v-else> Not specified </template>
    </span>
    <div v-if="confirmingChanges">
        <p>
            Are you sure you wish to adjust this booking fee to
            {{ formatCurrency(+manualFeeAmount.value) }}?
        </p>
        <div class="d-flex justify-content-end">
            <Button
                @click="cancelChanges"
                size="small"
                color="light"
                class="me-1"
                >Cancel</Button
            >
            <Button
                @click="storeChanges"
                :loading="storing"
                size="small"
                color="danger"
                >Confirm</Button
            >
        </div>
    </div>
    <div v-else-if="editManualFeeForm">
        <FormDollarInput
            v-model:input="manualFeeAmount"
            label="Enter Booking Fee"
            placeholder="e.g. 5.50"
            step=".01"
            input-group-class="input-group input-group-sm"
            input-class="form-control form-control-sm"
        />
        <div class="d-flex justify-content-end mt-2">
            <Button
                @click="cancelChanges"
                size="small"
                color="light"
                class="me-1"
                >Cancel</Button
            >
            <Button
                @click="confirmChanges"
                :loading="storing"
                size="small"
                color="primary"
                >Save</Button
            >
        </div>
    </div>
</template>

<script setup lang="ts">
import Booking from "@classes/Booking";
import {BookingEventType} from "@classes/BookingEvent";
import {formatCurrency} from "@classes/Helpers/Currency";
import Button from "@components/Button.vue"
import {FormDollarInput} from "@components/Forms";
import BookingResource from "@customTypes/resources/BookingResource";
import {computed, inject, ref} from "vue";

const toast: any = inject("toast");

const props = withDefaults(
    defineProps<{
        booking: BookingResource;
        readOnly?: boolean;
    }>(),
    {
        readOnly: false,
    }
);

const booking = computed(() => props.booking);

const initialTotalCost = computed(() => {
    let total_cost = (booking.value?.total_cost ?? 0) / 100;

    return total_cost;
});

const manualFeeAmount = ref<{
    value: string;
    required: boolean;
    valid: boolean;
    errors: string[];
}>({
    value: initialTotalCost.value?.toString() ?? "0",
    required: false,
    valid: true,
    errors: [],
});

const emit = defineEmits(["updated"]);

const editable = computed(() => {
    return (
        !props.readOnly &&
        (!props.booking.actual_pick_up_time ||
            props.booking?.events?.filter(
                (event) => event.type === BookingEventType.PICK_UP
            ).length === 0)
    );
});

const editManualFeeForm = ref(false);
const showEditFeeForm = function () {
    if (!editable.value) {
        return;
    }

    editManualFeeForm.value = true;
};

const cancelChanges = () => {
    editManualFeeForm.value = false;
    confirmingChanges.value = false;
};

const confirmingChanges = ref(false);
const confirmChanges = () => {
    confirmingChanges.value = true;
};

const storing = ref(false);
const storeChanges = function () {
    storing.value = true;
    Booking.update(props.booking, {
        manual_fee_amount: +manualFeeAmount.value.value,
    })
        .then((response) => {
            emit("updated", response.data);
            editManualFeeForm.value = false;
        })
        .catch((error) => {
            if (error.response.status && error.response.status === 422) {
                manualFeeAmount.value.errors = [
                    error.response.data.errors.manual_fee_amount[0],
                ];
            } else {
                toast.error(
                    error?.response?.data?.message ?? "Something went wrong. Our system administrators have been notified."
                );
            }
        })
        .finally(() => {
            storing.value = false;
            confirmingChanges.value = false;
        });
};
</script>
