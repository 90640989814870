import axios from "axios";
import { LaravelResourceResponse } from "@customTypes/LaravelCommonTypes";

export default class BookingCancellationReason {
    static index() {
        return axios.get<
            LaravelResourceResponse<App.Models.BookingCancellationReason[]>
        >(route("api.booking-cancellation-reasons.index"));
    }
}
