<template>
    <div class="d-block">
        <EditableSelect
            @updated="storeChanges"
            :value="booking.fare_collection_method"
            :options="options"
            :loading="storing"
        >
            {{ selected }}
        </EditableSelect>
        <FormValidationError :errors="errors" field="fare_collection_method" />
    </div>
</template>

<script setup>
import EditableSelect from "@components/Forms/EditableFields/EditableSelect.vue";
import FormValidationError from "@components/Forms/FormValidationError.vue";
import {computed, ref} from "vue";
import axios from "axios";

const props = defineProps({
    booking: Object,
})

const options = window.enums.fareCollectionMethod

const selected = computed(() => {
    return options[props.booking.fare_collection_method] ?? props.booking.fare_collection_method
})

const emit = defineEmits(['updated'])

const errors = ref({})
const storing = ref(false)
const storeChanges = function (method) {
    storing.value = true
    axios
        .put(route('api.bookings.update', { booking: props.booking.uuid }), {
            fare_collection_method: method
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            storing.value = false
        })
}
</script>