import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import FundingType from "@classes/FundingType";
import FundingTypeResource from "@customTypes/resources/FundingTypeResource";
import PersistedStore from "./PersistedStore";
import { useMessageStore } from "./MessageStore";

const dataExpiryThresholdMinutes = 90;

export const useFundingTypeStore = defineStore(
    "fundingTypeStore",
    () => {

        const messageStore = useMessageStore();
        const { addToastMessage } = messageStore;

        const fundingTypes = ref<FundingTypeResource[]>([]);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<FundingTypeResource[]> => {
            return new Promise((resolve, reject) => {
                FundingType.index()
                    .then((response) => {
                        fundingTypes.value = response.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(fundingTypes.value);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                fundingTypes.value
            );
        };

        const fundingTypeOptions = ref();

        const getFundingTypeOptions = () => {
            return new Promise((resolve, reject) => {
                FundingType.options()
                .then((response) => {
                    fundingTypeOptions.value = response.data.data;
                    resolve(fundingTypes.value);
                })
                .catch((error) => {
                    console.error(error);
                    addToastMessage('error', 'Failed to get Funging Type Options');
                    reject(error);
                });
            });
        }

        return {
            fundingTypes,
            lastRefreshTime,
            fundingTypeOptions,
            sync,
            getFundingTypeOptions,
        };
    },
    {
        persist: {
            key: "fundingTypeStore_" + window?.tenant?.tenancy_db_name,
        },
    }
);
