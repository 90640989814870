import { DateTime } from "luxon";

export default class PersistedStore {
    static dataLoadRequired(parameters: {
        lastRefreshTime: number | undefined;
        dataExpiryThresholdMinutes?: number;
        force?: boolean;
    }) {
        const {
            lastRefreshTime,
            dataExpiryThresholdMinutes,
            force = false,
        } = parameters;

        if (!lastRefreshTime || force) {
            return true;
        }

        const milliSecondsSinceLastRefresh =
            DateTime.now().toMillis() - lastRefreshTime;

        return (
            milliSecondsSinceLastRefresh >
            (dataExpiryThresholdMinutes ?? 0) * 60000
        );
    }

    static async sync(
        parameters: {
            lastRefreshTime: number | undefined;
            dataExpiryThresholdMinutes?: number;
            force?: boolean;
        },
        load: () => Promise<any>,
        persistedValue: any
    ) {
        if (!PersistedStore.dataLoadRequired(parameters)) {
            return persistedValue;
        }

        return await load();
    }
}
