<template>
    <tr>
        <td style="white-space: nowrap">
            {{ attribute }}
        </td>

        <td
            v-for="parentKey in ['attributes', 'old']"
            style="white-space: nowrap"
        >
            <div v-if="attribute == 'journey_id'">
                <ActivityLogJourneyChangedDisplay
                    :journeyId="propertiesToShow?.[parentKey]?.[attribute]"
                />
            </div>
            <div v-else>
                {{ getDisplayablePropertyValueByKey(attribute, parentKey) }}
            </div>
        </td>
    </tr>
</template>
<script setup lang="ts">
import { ref, toRefs } from "vue";
import { DateTime } from "luxon";
import ActivityLogJourneyChangedDisplay from "@components/ActivityLogsModalPlugin/ActivityLogJourneyChangedDisplay.vue";

const props = withDefaults(
    defineProps<{
        propertiesToShow: any;
        attribute: string;
    }>(),
    {}
);

const { propertiesToShow, attribute } = toRefs(props);

const isDatetimeAttribute = (attribute: string) => {
    return (
        attribute.includes("_at") ||
        attribute.includes("_time") ||
        attribute.includes("date") ||
        [
            "arrive_destination_not_before",
            "arrive_destination_no_later_than",
            "depart_origin_not_before",
            "depart_origin_no_later_than",
        ].includes(attribute)
    );
};

const getDisplayablePropertyValueByKey = (
    attribute: string,
    parentKey: "old" | "attributes"
): string => {
    if (
        !propertiesToShow.value ||
        !propertiesToShow.value?.[parentKey]?.[attribute]
    ) {
        return "-";
    }

    if (isDatetimeAttribute(attribute)) {
        try {
            return DateTime.fromISO(
                propertiesToShow.value?.[parentKey]?.[attribute]
            ).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
        } catch (e) {
            console.error(e);
        }
    }

    return propertiesToShow.value?.[parentKey]?.[attribute];
};
</script>
