import {ApiResourceResponseInterface} from "@customTypes/LaravelCommonTypes";
import axios, {AxiosResponse} from "axios";
import DriverPositionUpdateResource = App.Resource.DriverPositionUpdateResource;

interface DriverPositionUpdateParamsInterface {
    driver?: string;
    run?: string;
    vehicle?: string;
}

export default class DriverPositionUpdate {
    static async getVehiclePositions(parameters: DriverPositionUpdateParamsInterface): Promise<DriverPositionUpdateResource[]> {
        let response: AxiosResponse<ApiResourceResponseInterface<DriverPositionUpdateResource[]>> = await axios.get(route("api.vehicle-positions.index", parameters));
        return response.data.data;
    }

    static index = (parameters: {
        journey?: string;
        booking?: string;
    }) => {
        return axios.get<App.Resource.DriverPositionUpdateResource[]>(
            route("api.driver-position-updates.index", parameters)
        );
    };
}
