import axios from "axios";
import { ApiResourceResponseInterface } from "../types/LaravelCommonTypes";
import { DataTableInterface } from "@customTypes/DataTableTypes";

export default class Driver {
    static async index(params: Partial<DataTableInterface>): Promise<ApiResourceResponseInterface<App.Models.Driver>> {
        return await axios.get<App.Models.Driver>(route('api.drivers.index', params));
    }

    static async show(driverUuid: string) {
        return await axios.get<App.Models.Driver>(route("api.drivers.show", { driver: driverUuid }));
    }
}
