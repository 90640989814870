import { ref } from "vue";
import { defineStore } from "pinia";
import { DateTime } from "luxon";
import { each } from "lodash";

export const useMessageStore = defineStore('messageStore', () => {

    const toastMessages = ref<App.Message.ToastMessage>({});
    const fieldErrors = ref<App.Message.FieldError>({});

    const addToastMessage = (type: string, message: string) => {
        const key = DateTime.now().toUnixInteger();
        toastMessages.value[key] = {type: type, message: message};
        setTimeout(() => delete toastMessages.value[key], 5000);
    }

    const addFieldError = (field: string, messages: string[]) => {
        if (fieldErrors.value[field] == null) {
            fieldErrors.value[field] = [];
        }
        
        messages.forEach((message) => fieldErrors.value[field].push(message));
    }

    const processValidationErrors = (errors: any, customKey: string | null = null) => {
        each(errors, function (message, key) {
            addFieldError(customKey != null ? `${customKey}.${key}` : key, message);
        })
    }
    
    return {
        toastMessages,
        fieldErrors,
        addToastMessage,
        addFieldError,
        processValidationErrors,
    };
});