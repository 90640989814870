<template>
    <EditableTextarea
        @updated="storeChanges"
        :value="booking[noteField]"
        :loading="storing"
        placeholder="Click to add booking notes"
    />
</template>

<script setup lang="ts">
import EditableTextarea from "@components/Forms/EditableFields/EditableTextarea.vue";
import { computed, ref } from "vue";
import axios from "axios";

const props = withDefaults(
    defineProps<{
        booking: App.Models.Booking;
        noteField?: "notes" | "client_notes";
    }>(),
    {
        noteField: "notes",
    }
);
const emit = defineEmits(["updated"]);

const errors = ref({});
const storing = ref(false);
const storeChanges = function (notes) {
    storing.value = true;
    axios
        .put(route("api.bookings.update", { booking: props.booking.uuid }), {
            [props.noteField]: notes,
        })
        .then((response) => {
            emit("updated", response.data);
        })
        .catch((error) => {
            errors.value = error.response.data.errors;
        })
        .finally(() => {
            storing.value = false;
        });
};
</script>

<style scoped></style>
