import Booking from "@classes/Booking";
import ActivityLogsModal from "./ActivityLogsModal.vue";
import { nextTick, ref } from "vue";

const activityLogsModal = {
    install: (app: any) => {
        const activityLogs: any = {};
        let activityLogSubjectType = ref<string | null>(null);
        let activityLogSubjectUuid = ref<string | null>(null);

        activityLogs.show = (type: string, uuid: string) => {
            // Force the watcher to trigger
            activityLogSubjectType.value = type;
            nextTick(() => {
                activityLogSubjectType.value = type;
            });
            activityLogSubjectUuid.value = uuid;
        };

        app.provide("activityLogSubjectType", activityLogSubjectType);
        app.provide("activityLogs", activityLogs);
        app.provide("activityLogSubjectUuid", activityLogSubjectUuid);
        app.component("activity-logs-modal", ActivityLogsModal);
    },
};
export default activityLogsModal;
