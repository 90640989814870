import CancelBooking from "@components/Bookings/CancelBooking/CancelBooking.vue";
import {Ref, ref} from 'vue'

interface CancelBookingInterface {
    submitFor(bookingToCancel: {}, reload: boolean): void;
    updateCancelledBooking(bookingToCancel: unknown | null): void;
    getCancelledBooking(): unknown;
    reloadOnSubmit(): boolean;
}


const cancelBookingPlugin = {
    install: (app, customOptions = {}) => {
        const booking = ref({})
        const reloadOnSubmit: Ref<boolean> = ref(true)
        const cancelledBooking: Ref<unknown> = ref(null)

        const cancelBooking: CancelBookingInterface = {
            submitFor(bookingToCancel: {}, reload: boolean = true) {
                booking.value = bookingToCancel
                reloadOnSubmit.value = reload
            },

            updateCancelledBooking(booking): void {
                cancelledBooking.value = booking
            },

            getCancelledBooking() {
                return cancelledBooking.value
            },

            reloadOnSubmit(): boolean {
                return reloadOnSubmit.value
            }
        }

        app.provide('bookingToBeCancelled', booking)
        app.provide('cancelBooking', cancelBooking)
        app.component("cancel-booking", CancelBooking);
    }
}

export default cancelBookingPlugin
