<template>
    <label
        :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
        :for="inputName"
    >
        {{ label }}
    </label>
<!--    <IMaskComponent-->
<!--        :value="input.value"-->
<!--        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"-->
<!--        :mask="mask?.mask"-->
<!--        :name="inputName"-->
<!--        :placeholder="placeholder"-->
<!--        type="datetime-local"-->
<!--        :min="min"-->
<!--        :max="max"-->
<!--        @input="debouncedValidateInput"-->
<!--    />-->
    <input
        @input="debouncedValidateInput"
        :value="input.value"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :name="inputName"
        type="datetime-local"
        :min="min"
        :max="max"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage, defaultInputName} from '@components/Utils'
import {IMaskComponent} from "vue-imask";
import {debounce, isEmpty} from "lodash";

const {
    input,
    label,
    placeholder,
    inputClass,
    labelClass,
    errorClass,
    inputName,
    validation,
    mask,
    optional
} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: defaultInputName()
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.string().max(255),
    },
    mask: {
        type: Object,
        default: null
    },
    optional: {
        type: Boolean,
        default: false
    },
    min: {
        type: String,
        default: null
    },
    max: {
        type: String,
        default: null
    },
})

const mounted = ref(false)
onReady(() => mounted.value = true)

const emit = defineEmits(['update:input'])

const {errorMessage, setValue, validate, meta} = useField(inputName, validation)
const validateInput = (event) => {
    if (validation.spec.nullable && isEmpty(event.target.value)) {
        let returnValue = copyValues(input);
        returnValue.value = null;
        returnValue.valid = true;
        emit('update:input', returnValue);
        return;
    }

    const value = event.target.value;

    setValue(value)
    validate().then(() => {
        let returnValue = copyValues(input)
        returnValue.value = meta.valid ? value : null
        returnValue.valid = meta.valid
        emit('update:input', returnValue)
    });
}
const debouncedValidateInput = debounce(validateInput, 1000);
</script>
