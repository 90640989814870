<template>
    <div
        @mouseenter="copyHidden = false"
        @mouseout="hideCopyButton"
        class="d-inline-block">
        #{{ booking.uuid }}
        <template v-if="copiedSuccessfully">
            <SvgIcon
                type="mdi"
                :path="tickIcon"
                v-tooltip="'Copied!'"
                class="w-4 h-4 text-success"
            ></SvgIcon>
        </template>
        <a
            v-if="!copiedSuccessfully"
            v-tooltip="'Copy link to booking'"
            :class="copyHidden ? 'invisible' : 'visible'"
            href="javascript: void(0)"
            @click="copyToClipboard"
        >
            <SvgIcon
                type="mdi"
                :path="linkIcon"
                class="w-4 h-4 text-primary"
            ></SvgIcon>
        </a>
    </div>
</template>

<script>
import {defineComponent, ref} from 'vue';
export default defineComponent({
    name: 'BookingReferenceNumber',
})
</script>
<script setup>
import {ref, reactive} from 'vue';
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiLinkVariant, mdiCheckCircle} from "@mdi/js";
const { booking } = defineProps({
    booking: {
        required: true,
        type: Object,
    }
})
let copiedSuccessfully = ref(false)
let copyHidden = ref(true)

const linkIcon = ref(mdiLinkVariant)
const tickIcon = ref(mdiCheckCircle)

const copyToClipboard = function () {
    navigator.clipboard.writeText(route('bookings.show', {booking: booking.uuid}))
    setTimeout(() => copiedSuccessfully.value = true, 300)
    setTimeout(() => copiedSuccessfully.value = false, 1300)
}

const hideCopyButton = function () {
    setTimeout(() => copyHidden.value = true, 1000)
}

</script>

<style scoped>

</style>
