

<template>
    <div :class="[columns, mb]" class="has-validation">
        <slot></slot>
    </div>
</template>

<script setup>
    import { computed } from 'vue'

    const props = defineProps({
        xs: {
            type: String,
            default: ''
        },
        sm: {
            type: String,
            default: ''
        },
        md: {
            type: String,
            default: ''
        },
        lg: {
            type: String,
            default: ''
        },
        xl: {
            type: String,
            default: ''
        },
        mb: {
            type: String,
            default: 'mb-3'
        },
    })

    const columns = computed(() => {
        let cols = ''

        if (props.xl.length) {
            cols += `col-xl-${props.xl}`
        }
        if (props.lg.length) {
            cols += ` col-lg-${props.lg}`
        }
        if (props.md.length) {
            cols += ` col-md-${props.md}`
        }
        if (props.sm.length) {
            cols += ` col-sm-${props.xl}`
        }
        if (props.xs.length) {
            cols += ` col-xs-${props.xs}`
        }

        return cols
    })
</script>