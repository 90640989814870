<template>
    <div class="custom-control form-check form-switch">
        <input
            :id="`switch-${uid}`"
            class="form-check-input boolean"
            type="checkbox"
            role="switch"
            @change="(e: any) => $emit('update:modelValue', e?.target?.checked)"
            :checked="value"
        />
        <label v-if="label" class="custom-control-label" :for="`switch-${uid}`">{{
            label
        }}</label>
    </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, toRef } from "vue";

const {uid} = getCurrentInstance();

const props = withDefaults(
    defineProps<{
        modelValue?: boolean;
        label?: string;
    }>(),
    {
        modelValue: true,
    }
);

let value = toRef(props, "modelValue");

const emit = defineEmits<{
    (e: "update:modelValue", newValue: boolean): void;
}>();
</script>
