import { DateTime } from "luxon";
import DatetimeHelper from "./DatetimeHelper";

//Helper class so I don't have to keep converting the datetime strings to luxon
export default class JourneyDatetime {
    journey: App.Models.Journey;

    constructor(journey: App.Models.Journey) {
        this.journey = journey;
    }

    startTime() {
        return DatetimeHelper.convertToLuxon(this.journey.start_time);
    }

    endTime() {
        return DatetimeHelper.convertToLuxon(this.journey.end_time);
    }
}
