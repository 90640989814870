<template>
    <div class="block">
        <p
            v-if="!editing || loading"
            @click="editing = !(loading || disabled)"
            :class="[(loading || disabled) ? null : 'click-to-edit', 'mb-0']"
        >
            <template v-if="value !== null && value.trim().length > 0">{{ label ?? value }}</template>
            <template v-else><small><em>{{ placeholder }}</em></small></template>
        </p>
        <textarea
            v-model="fieldValue"
            v-focus
            @focusout="update"
            v-if="editing && !loading"
            class="form-control form-control-sm"
            :disabled="loading || disabled"
        >{{ value }}</textarea>
        <Spinner class="ms-1" v-if="loading" type="border" size="small"></Spinner>
    </div>
</template>

<script>
export default {
    name: "EditableTextarea"
}
</script>
<script setup>
import {ref, onBeforeMount} from 'vue'
import Spinner from "@components/Spinner.vue";

const fieldValue = ref(null)
const props = defineProps({
    value: String,
    loading: Boolean,
    disabled: Boolean,
    label: {
        type: String,
        required: false,
        default: null,
    },
    placeholder: {
        type: String,
        required: false,
        default: 'Click to add',
    },
})
const emit = defineEmits(['updated'])

let editing = ref(false)
const update = function () {
    editing.value = false
    emit('updated', fieldValue.value)
}

onBeforeMount(() => {
    fieldValue.value = props.value
})

</script>

<style scoped>

</style>
