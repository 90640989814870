import axios from "axios";
import {ApiResourceResponseInterface} from "../types/LaravelCommonTypes";

export enum JourneyStatus {
    Future = "future",
    Verified = "verified",
    WaitingVerification = "waiting_verification",
    Unmet = "unmet",
    Cancelled = "cancelled",
    PendingInformation = "pending_information",
    Draft = "draft",
}

export default class JourneyApi {
    static index(parameters: { date?: string }) {
        return axios.get(route("api.journeys.index", parameters));
    }

    static tempIndex(parameters: { date?: string }) {
        return axios.get(route("api.journeys.temp", parameters));
    }

    static dispatchIndex(parameters: { date?: string }) {
        return axios.get(route("api.journeys.dispatch-index", parameters));
    }

    static show(uuid: string) {
        return axios.get<ApiResourceResponseInterface<App.Models.Journey>>(
            route("api.journeys.show", {journey: uuid})
        );
    }

    static async updateStatus(uuid: string, status: string) {
        return await axios.patch(
            route('api.journeys.status.update', {journey: uuid}),
            {status: status,}
        );
    }
}
