<template>
    <label
        v-if="label && label.length > 0"
        :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
        :for="inputName"
    >
        {{ label }}
    </label>
    <slot name="label-suffix"></slot>
    <IMaskComponent
        :value="input.value?.toString()"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :disabled="disabled"
        :mask="mask?.mask"
        :maxlength="maxlength"
        :name="inputName"
        :placeholder="placeholder"
        :tabindex="tabindex"
        :type="type"
        @accept="validateInput"
        @complete="validateInput"
    />
    <div v-if="maxlength && input.value" class="text-end text-xs text-secondary">{{ input.value.length }}/{{ maxlength }}</div>
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup lang="ts">
import {useField} from 'vee-validate'
import {ref} from "vue";
import * as yup from 'yup'
import {copyValues, isInvalid, getErrorMessage, defaultInputName, onReady} from '@components/Utils'
import {IMaskComponent} from "vue-imask";

const {
    input,
    label,
    placeholder,
    inputClass,
    labelClass,
    errorClass,
    inputName,
    validation,
    mask,
    optional,
    disabled,
    type,
    tabindex,
    maxlength,
} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: defaultInputName()
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.string().max(255),
    },
    mask: {
        type: Object,
        default: {
            mask: String,
        }
    },
    optional: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    type: {
        type: String,
        default: 'text'
    },
    tabindex: {
        type: Number,
        default: 0
    },
    maxlength: {
        type: String,
        required: false,
        default: null,
    },
})

const emit = defineEmits(['update:input']);
const {errorMessage, setValue, validate, meta} = useField(inputName ?? defaultInputName(), validation);
const mounted = ref(false)

onReady(() => mounted.value = true)

const validateInput = (value: unknown, event: CustomEvent) => {
    if(!mounted.value) return
    setValue(value)
    validate().then(() => {
        let returnValue = copyValues(input)
        returnValue.value = value;
        returnValue.valid = meta.valid
        emit('update:input', returnValue)
    })
}
</script>
