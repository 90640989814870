import axios from "axios";

export default class Region {
    static async index() {
        return await axios.get<App.Models.Region[]>(route("api.regions.index"));
    }

    static async selectRegion(region: App.Models.Region | null) {
        return await axios.post(route('api.regions.set-filter'), {region: region?.uuid });
    }
}
