<template>
    <div class="d-flex flex-column justify-content-center align-items-center p-2">
        <span v-if="props.showText && props.textPosition == 'before'" class="mb-1" :class="props.color">{{ props.text }}</span>
        <div :class="[props.spinnerClass, props.color]" role="status">
            <span class="visually-hidden">{{ props.text }}</span>
        </div>
        <span v-if="props.showText && props.textPosition == 'after'" class="mt-1" :class="props.color">{{ props.text }}</span>
    </div>
</template>

<script setup lang="ts">
    const props = withDefaults(
        defineProps<{
            text?: string,
            showText?: boolean,
            textPosition?: string,
            spinnerClass?: string,
            color?: string,
        }>(), {
            text: 'Loading...',
            showText: true,
            textPosition: 'after',
            spinnerClass: 'spinner-border',
            color: 'text-dark',
        }
    ); 
</script>