import axios from "axios";
import { LaravelPaginate } from "../types/LaravelCommonTypes";
import ActivityLogResource from "@customTypes/resources/ActivityLogResource";

export interface ListActivityLogsRequestParameters {
    subject_type: string;
    subject_uuid: string;
    page?: number;
}

export default class ActivityLog {
    static index(parameters: ListActivityLogsRequestParameters) {
        return axios.get<LaravelPaginate<ActivityLogResource>>(
            route("api.activity-logs.index", parameters)
        );
    }
}
