<template>
    <span>
        <i v-if="flag" v-tooltip="flag" :style="{ color: flagColor }" class="mdi mdi-account-circle"></i>
        <a :href="route('clients.show', {client: client.uuid})">{{ client.name }}</a>
    </span>
</template>

<script>
export default {
    name: "ClientName",
    props: {
        client: Object,
    },
    computed: {
        flagColor: function () {
            switch (this.flag) {
                case 'Client is a carer':
                    return '#9b59b6'
                default:
                    return ''
            }
        },
        flag: function () {
            if (this.client.classification && this.client.classification.code === 'CAR') {
                return 'Client is a carer'
            }

            return null
        }
    }
}
</script>

<style scoped>

</style>
