<template>
    <div
        id="bookingDetailsModal"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-xl">
            <div class="modal-content" v-if="booking">
                <div class="modal-header">
                    <h4 class="header-title mt-0">Booking Details</h4>
                    <button
                        aria-label="Close"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        type="button"
                        @click="onModalClose"
                    ></button>
                </div>
                <div class="modal-body pb-3">
                    <div v-if="showSuccessMessage" class="alert alert-success">
                        Success! The booking has been allocated.
                    </div>
                    <BookingDetails
                        :key="refreshKey"
                        :booking="booking"
                        @bookingAllocated="allocate"
                    ></BookingDetails>
                </div>
                <div v-if="redirectAfterAllocation" class="modal-footer">
                    <button class="btn btn-light" @click="redirectToDispatch">
                        Allocate Later
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BookingDetails from "@components/Bookings/BookingDetails.vue";
import {inject} from "vue";

export default {
    name: "BookingDetailsModal",
    components: {
        BookingDetails,
    },
    data() {
        return {
            refreshKey: Date.now(),
            booking: inject("booking"),
            bookingDetails: inject("bookingDetails"),
            options: inject("showForBookingOptions"),
            journeys: null,
            modal: null,
            redirectAfterAllocation: false,
            hideAfterAllocation: true,
            showSuccessMessage: false,
        };
    },
    watch: {
        booking: function () {
            this.showSuccessMessage = false;
            if (this.booking) {
                this.showForBooking(this.booking);
            }
        },
    },
    methods: {
        initialiseModal(staticModal = false) {
            this.modal = new bootstrap.Modal(
                document.getElementById("bookingDetailsModal"),
                {
                    keyboard: !staticModal,
                    backdrop: staticModal ? "static" : true,
                }
            );
        },
        showForBooking(
            booking,
            redirectAfterAllocation = false,
            hideAfterAllocation = true
        ) {
            this.booking = booking;
            this.refreshKey = Date.now();
            this.redirectAfterAllocation = redirectAfterAllocation;
            this.hideAfterAllocation = hideAfterAllocation;
            this.initialiseModal(this.redirectAfterAllocation);
            this.modal.show();
        },
        showForBookingId(bookingId) {
            this.$http
                .get(this.route("api.bookings.show", { booking: bookingId }))
                .then((response) => {
                    this.showForBooking(response.data);
                });
        },
        allocate(bookingJustAllocated) {
            this.bookingDetails.updateAllocatedBooking(bookingJustAllocated)
            if (this.redirectAfterAllocation) {
                this.showSuccessMessage = true;
                setTimeout(() => this.redirectToDispatch(), 2000);
            } else if (this.hideAfterAllocation) {
                this.showSuccessMessage = true;
                this.booking = null;
                this.modal.hide();
                this.onModalClose();
            }
        },
        onModalClose() {
            this.options?.onDismiss();
        },
        redirectToDispatch() {
            window.location = this.route("dispatch.index");
        },
    },
    mounted() {
        this.initialiseModal();
    },
};
</script>

<style scoped></style>
