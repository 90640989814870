<template>
    <div :id="modalId" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="header-title mt-0">GPS Updates</h4>
                </div>
                <div class="modal-body pb-3">
                    <div v-if="!loading" :style="{}">
                        <DriverPositionUpdateMap
                            v-if="driverPositionUpdatesModalParameters !== null"
                            :parameters="driverPositionUpdatesModalParameters"
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <Button class="btn btn-secondary" @click="close">
                        Close
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from "@components/Button.vue";
import DriverPositionUpdateMap from "@components/DriverPositionUpdateModalPlugin/DriverPositionUpdateMap.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import {computed, inject, onMounted, ref, watch} from "vue";

const driverPositionUpdatesModalParameters = inject<any>(
    "driverPositionUpdatesModalParameters"
);

const loading = ref(false);

const modalId = ref("DriverPositionUpdateModal");

//@ts-ignore
const modal = ref<null | bootstrap.Modal>(null);

const emit = defineEmits<{
    (e: "update:show", value: boolean): void;
}>();

const show = computed({
    get() {
        return driverPositionUpdatesModalParameters.value !== null;
    },
    set(value) {
        if (!value) {
            driverPositionUpdatesModalParameters.value = null;
        }
    },
});

const applyChanges = () => {
    show.value = false;
    // emit("update:show", false);
};

const close = () => {
    show.value = false;
    // emit("update:show", false);
};

const initModal = () => {
    const modalElement = document.getElementById(modalId.value);
    if (!modalElement) {
        return;
    }
    modal.value = new bootstrap.Modal(modalElement, {});
};

const loadDriverPositionUpdates = () => {};

watch(
    () => driverPositionUpdatesModalParameters.value,
    (newValue) => {
        if (newValue) {
            modal.value?.show();
            loadDriverPositionUpdates();
        } else {
            modal.value?.hide();
        }
    }
);

onMounted(() => {
    initModal();
});
</script>
